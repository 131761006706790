<template>
  <v-dialog v-model="showModal" width="800px">
    <v-card>
      <v-card-title>
        <span class="headline">{{ $tc('title.upload_pagamento', 1) }}</span>
      </v-card-title>
      <v-card-text>
        <v-container grid-list-md class="py-0">
          <v-row>
            <v-col cols="12" class="text-center">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon
                          v-on="on"
                          @click="exportaModelo()">
                    <v-icon>
                      font_download
                    </v-icon>
                    <span>{{ $t('label.exportar_modelo') }}</span>
                  </v-btn>
                </template>
                <span>{{ $t('label.exportar_modelo') }}</span>
              </v-tooltip>
            </v-col>
            <v-col cols="12" sm="12">
              <upload-file
                v-if="tipoImportacao"
                name="pagamento-upload"
                :id="uploadFileDropzoneId"
                :url="importUrl"
                @UPLOADFILE_SUCCESS="uploadSuccess"
                @UPLOADFILE_ERROR="uploadError"
                @UPLOADFILE_SENDING="enviandoArquivo"
                :accepted-file-types="tipoImportacao.arquivosSuportados"/>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import UploadFile from '../../../shared-components/upload/UploadFile';
import exportacao from '../../../common/functions/exportacao';

import { basePath } from '../../../common/resources/importacoes';

export default {
  computed: {
    ...mapGetters('importacao', {
      tiposImportacao: 'getTiposImportacao',
    }),
    tipoImportacao() {
      return this.tiposImportacao.find((el) => el.nome === 'atualizar_status_pgto_acao');
    },
    importUrl() {
      return `${basePath}importacao`;
    },
    subtitle() {
      return this.$t('subtitle.upload_pagamento');
    },
    uploadFileDropzoneId() {
      return `myVueDropzone${(Math.random() * Math.random() * 100).toString().replace('.', '')}`;
    },
  },
  components: {
    UploadFile,
  },
  methods: {
    toggle() {
      this.showModal = !this.showModal;
    },
    enviandoArquivo(data, xhr, formData) {
      formData.append('id_tipo_importacao', 6);
    },
    uploadError(file, msg) {
      this.$toast(msg || this.$t('message.erro_tente_importar_novamente'));
    },
    uploadSuccess() {
      this.toggle();
      this.$toast(this.$t('message.importado_em_breve_sera_processado'), 'bottom', 8E3);
    },
    exportaModelo() {
      const params = {
        modelo: true,
      };

      const cb = () => {
        this.$toast(this.$t('message.exportacao_download'));
      };

      exportacao.exportar(cb, 'pagamento_atualizacao', this, params);
    },
  },
  data() {
    return {
      showModal: false,
    };
  },
};
</script>
