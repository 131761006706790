<template>
  <div>
    <v-container fluid px-0>
      <v-row align="center" class="mx-0">
        <v-col cols="12" sm="4" align-self="start">
          <v-text-field
            v-model="pesquisaCards"
            append-icon="search"
            :label="`${$tc('label.pesquisar', 1)}`"
            single-line
            hide-details
            clearable
            @input="filtrar"
          ></v-text-field>
        </v-col>

        <v-container fluid grid-list-md class="Container_Cards" v-if="listaCards.length">
          <v-data-iterator
            :items="listaCards"
            :options.sync="pagination"
            :hide-default-footer="12 >= totalPage"
            :server-items-length="totalPage"
            :footer-props="{
              itemsPerPageOptions: rowsPerPageItems,
            }">
            <template v-slot:default="props">
              <v-row justify="start">
                <v-col
                  v-for="item in props.items"
                  :key="item.titulo"
                  cols="12"
                  sm="4"
                 md="3">
                  <v-hover v-slot:default="{ hover }">
                    <v-card :class="`elevation-${hover ? 5 : 1}`" fill-height class="clickable Card_Recebimento" @click="selectItem(item)">
                      <v-card-title class="Card_Title pa-2 justify-center" >
                        <div style="width: 100%;" class="text-center mb-0">
                          {{ item.titulo | truncate(25) }}
                        </div>
                      </v-card-title>
                      <v-card-text class="Card_Content headline font-weight-light">
                        <v-row justify="center" align="center">
                          <v-col cols="12">
                            <v-row justify="center" align="center" class="my-0">{{ getMoney(item.totalRecebimentosPorFornecedor) }}</v-row>
                            <v-row justify="center" align="center" class="my-0">{{ getPercent(item.valorPercentual) }}</v-row>
                          </v-col>
                        </v-row>
                      </v-card-text>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
            <template v-slot:no-data>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
            <template v-slot:no-results>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  getMoney,
  getPercent,
} from '../../../common/functions/helpers';
import { generateComputed } from '../../../common/functions/roles-computed-generator';
import { listarRecebimentosPorFornecedor } from '../../../common/resources/liquidacao/recebimento';

export default {
  name: 'RecebimentoDadosNivel1Agrupado',
  components: {
  },
  data() {
    return {
      pesquisaCards: '',
      showNav: true,
      mensagemConfirmacao: '',
      listaCards: [],
      filtrosAplicados: this.filtros,
      rowsPerPageItems: [12],
      pagination: {
        itemsPerPage: 12,
      },
      totalPage: 0,
      buscando: false,
      esperarDigitar: false,
      abas: [
        {
          titulo: this.$tc('label.status', 1),
          valor: 'Status',
          icon: 'update',
        },
        {
          titulo: this.$tc('label.fornecedor', 1),
          valor: 'Fornecedor',
          icon: 'fa-industry',
        },
      ],
    };
  },
  watch: {
    filtroLiquidacaoAcao: {
      handler() {
        this.filtrosAplicados = this.filtroLiquidacaoAcao;
        this.buscar();
      },
    },
    pagination: {
      handler() {
        if (this.filtroLiquidacaoAcao) {
          this.buscar();
        }
      },
      deep: true,
    },
  },
  filters: {
    truncate(value, limit) {
      if (value.length > limit) {
        value = value.substring(0, (limit - 3)).concat('...');
      }
      return value;
    },
  },
  computed: {
    ...generateComputed('REC_ACAO', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    ...mapGetters('filtros', [
      'filtroLiquidacaoAcaoNivel1',
      'filtroLiquidacaoAcao',
    ]),
  },
  methods: {
    getMoney,
    getPercent,
    ...mapActions('filtros', [
      'setFiltroLiquidacaoAcaoNivel1',
    ]),
    selectItem(item) {
      this.$emit('Recebimento__SelecionaItemNivel1', item, 'nivel2Recebimento');
    },
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 1000);
    },
    buscar() {
      if (this.buscando) return;
      this.buscando = true;

      const params = {
        ...this.filtrosAplicados,
        filtro: this.pesquisaCards,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      listarRecebimentosPorFornecedor(params, this.$resource)
        .then((response) => {
          this.listaCards = response.data.resposta;
          this.rowsPerPageItems = [12, 16, 20, 24, 28, 32, 36, 40];
          this.totalPage = response.data.quantidadeRegistrosPagina;
          setTimeout(this.finalizandoBusca);
          this.preencherFiltrosNivel1Vuex();
        })
        .catch((err) => {
          this.finalizandoBusca();
          this.$error(this, err);
        });
    },
    finalizandoBusca() {
      this.buscando = false;
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page;
      const quantidatePorPagina = this.pagination.itemsPerPage;

      this.setFiltroLiquidacaoAcaoNivel1({
        pagina,
        quantidatePorPagina,
      });
    },
    preencherFiltrosNivel1() {
      this.pagination.page = this.filtroLiquidacaoAcaoNivel1.pagina;
      this.pagination.itemsPerPage = this.filtroLiquidacaoAcaoNivel1.quantidatePorPagina;
    },
  },
  mounted() {
    if (this.filtroLiquidacaoAcaoNivel1) {
      this.preencherFiltrosNivel1();
    }

    if (this.filtroLiquidacaoAcao) {
      this.filtrosAplicados = this.filtroLiquidacaoAcao;
      this.buscar();
    }
  },
};
</script>
<style>
  .clickable {
    cursor: pointer;
  }
  .Card_Recebimento {
    min-height: 146px !important;
    height: 100%;
  }
  .Card_Recebimento .Card_Title, .Card_Recebimento .Card_Content {
    color: #757575;
  }
</style>
